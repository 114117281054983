@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Rammetto+One&display=swap');

.contact {
    padding-top: 100px;
    margin: 0;
}

.contact-heading {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.address-heading {
    text-align: center;
    font-weight: 600;
    margin-bottom: 30px;
}

#address {
    padding-top: 100px;
    background-color: rgb(255, 255, 255);
}

#contact-form {
    box-shadow: 0px 0px 30px rgb(164, 164, 164);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

#address {
    padding-top: 200px;
}

#address .huon {
    text-align: center;
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 30px;
    font-family: 'Monoton', cursive;
}

#address .huon-1 {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 400;
    font-family: 'Rammetto One', cursive;
}

#address h6 {
    text-align: center;
}

#address h6 .location-icon {
    width: 17px;
    margin-bottom: 7px;
    margin-right: 2px;
}

#address a {
    display: block;
    text-decoration: none;
    text-align: center;
    color: black;
}

#address a .mail-icon {
    width: 15px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.submit-btn {
    color: white;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    background-color: #0d6efd;
    border: none;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgb(116, 116, 116) !important;
}

@media (max-width: 900px) {
    #contact-form {
        width: 100%;
        padding: 10px;
        background-color: rgb(255, 255, 255);
    }

    #address {
        padding-top: 20px;
        padding-bottom: 50px;
    }

    #address .huon {
        margin-bottom: 10px;
    }

    #address .huon-1 {
        font-size: 1.2rem;
        margin-bottom: 25px;
    }

    #address h6 {
        font-size: 0.8rem;
        text-align: center;
    }

    #address a {
        font-size: 0.8rem;
        display: block;
        text-decoration: none;
        text-align: center;
        color: black;
    }

}
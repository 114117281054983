/* Navbar */

.navbar {
    position: fixed;
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    background-color: black;
}

.nav {
    width: 100%;
    margin: 0px 200px 0px 200px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo img {
    width: 150px;
}

.menu.active {
    text-decoration: none;
    color: rgb(0, 171, 85);
}

.menu {
    margin: 0px 40px 0px 0px;
    text-decoration: none;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.9rem;
    font-family: "Public Sans", sans-serif;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: white;
}

.menu:hover {
    opacity: 0.48;
    text-decoration: none;
    color: white;
}

.menu.white {
    color: rgb(255, 255, 255);
}

.menu.black {
    color: black;
}

.enquire-now-nav {
    padding: 10px 16px;
    border-radius: 8px;
    background-color: #00ab55;
}

.side-nav {
    display: none;
}

.side-nav a {
    text-decoration: none;
    color: white;
}

.hide {
    display: none;
}


.swathi {
    display: flex;
    flex-direction: row;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: fixed;
    top: 80px;
    left: 10%;
    right: 10%;
    width: 800px;
    overflow: hidden auto;
    outline: 0px;
    padding: 40px 24px 24px;
    margin: auto;
    border-radius: 16px;
    box-shadow: rgba(145, 158, 171, 0.361) 0px 24px 48px 0px;
    z-index: 2000;
}

.swathi a {
    text-decoration: none;
    color: black;
}

.swathi li {
    list-style: disc;
    color: black;
}

.navbar.black {
    background-color: black;
}

.navbar.transparent {
    /* How it will look when scrolled */
    background-color: rgba(255, 255, 255, 0.795);
    backdrop-filter: blur(10px);
    box-shadow: rgba(199, 199, 199, 0.361) 0px 14px 20px;
}

@media (max-width:1200px) {
    .nav {
        width: 100%;
        margin: 0px 10px 0px 10px;
        padding: 10px;
    }
}

@media (max-width: 960px) {
    .logo img {
        width: 100px;
    }

    /*
    .nav-menus {
        display: none;
    }
    */
    .nav-menus {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        padding: 80px 30px 30px 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        background-color: white;
        height: 700px;
    }

    .menu {
        width: 90%;
        text-align: left;
        font-weight: 400;
        margin: 10px;
        padding: 0;
        background-color: white;
        color: black;
    }

    .menu:hover {
        opacity: 1;
        text-decoration: none;
        color: rgb(87, 87, 87);
    }

    .enquire-now-nav {
        padding: 5px 10px;
        border-radius: 8px;
        background-color: #00ab55;
    }

    .side-nav {
        display: block;
        z-index: 2000;
    }

    .side-nav .close-menu {
        text-decoration: none;
        color: black;
    }

    .services-dropdown {
        display: none;
    }

    .menu.sub-menu {
        text-align: left;
        color: #343434;
    }

    .hidden {
        display: none;
    }

    .swathi {
        display: block;
        background-color: rgb(255, 255, 255);
        position: initial;
        width: auto;
        overflow: hidden auto;
        padding: 0px;
        margin: auto;
        border-radius: 0;
        z-index: 2000;
        box-shadow: none;
    }

    .swathi h6 {
        display: none;
    }

    .swathi ul {
        padding: 5px;
    }


    .swathi li {
        list-style: disc;
        color: black;
        padding: 5px;
    }

    .swathi li a {
        font-size: 0.7rem;
        text-decoration: none;
    }
}
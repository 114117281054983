#section1 {
    position: relative;
    height: 800px;
    padding: 200px 80px 200px 80px;
    position: relative;
}

#section1 h1 {
    font-size: 5.5rem;
    font-weight: 800;
    color: white;
    margin-bottom: 30px;
}

#section1 h6 {
    color: white;
}

#section1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0);
    height: 800px;
    z-index: -1;
}

#section1 .learn-more-btn {
    color: white;
    text-decoration: none;
    background-color: #25d366;
    padding: 10px 30px;
    border-radius: 10px;
}

#section1 .learn-more-btn:hover {
    opacity: 0.7;
}

/* section2 */

#section2 {
    padding: 100px 80px 100px 80px;
    height: auto;
    background-color: white;
    z-index: 20;
}

#section2 h1 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 40px;
    letter-spacing: 2px;
    text-align: center;
}

.card-container .icon-container,
.content-contianer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card-container .icon-container {
    padding: 30px 30px 40px 30px;
}

.icon-container img {
    width: 60px;
}

.card-container .content-contianer h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.outer-container {
    border-radius: 15px;
    box-shadow: rgb(145 158 171 / 40%) -40px 40px 80px 0px;
}

.inner-container {
    border-radius: 15px;
    box-shadow: rgb(145 158 171 / 40%) -40px 40px 80px 0px;
}

/* button effects */
.box {
    width: 130px;
    background: #00ab55;
    padding: 10px 20px;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .01);
    text-align: center;
    text-decoration: none;
    color: white;
    font-weight: 600;
}

.box:hover {
    opacity: 0.7;
    color: white;
}

.box::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(to left,
            #ff5770,
            #e4428d,
            #c42da8,
            #9e16c3,
            #6501de,
            #9e16c3,
            #c42da8,
            #e4428d,
            #ff5770);
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
}


@keyframes animateGlow {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 200% 50%;
    }
}

/* whats app floating icon */
.whats-app-btn {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 60px;
    right: 30px;
    background-color: #00ab55;
    color: #FFF;
    border-radius: 50%;
    text-align: center;
    font-size: 25px;
    box-shadow: 2px 2px 3px rgba(167, 221, 172, 0.196);
    z-index: 100;
    padding: 10px;
    animation: ripple 1.5s linear infinite;
}

.whats-app-btn .whatsapp-btn {
    font-size: 30px;
    color: white;
}

/* section3 */

#section3 {
    padding: 100px 80px 100px 80px;
    width: 100%;
    height: 800px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#section3 h1 {
    font-size: 4rem;
    font-weight: 800;
}

/* section4 */

#section4 {
    padding: 100px 80px 100px 80px;
    width: 100%;
    height: 800px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}

#section4 h1 {
    font-size: 4rem;
    font-weight: 800;
}

/* section5 */

#section5 {
    padding: 100px 80px 100px 80px;
    width: 100%;
    height: auto;
    background-color: rgb(246, 246, 246);
}

#section5 h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 50px;
}

#section5 .card-container {
    margin-left: auto;
    margin-right: auto;
}

#section5 .card-container .card {
    padding: 30px 30px;
    background-color: white;
    border-radius: 20px;
    border: none;
}

#section5 .card-container .card h4 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 25px;
}

#section5 .card-container .card p {
    text-align: center;
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 25px;
    color: gray;
}

#section5 .card-container .card .heart {
    width: 40px;
    margin-bottom: 25px;
}

#section5 .card-container .card .tick-li {
    width: 20px;
    margin-bottom: 5px;
    margin-right: 4px;
}

#section5 .card-container .card ul {
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
}

#section5 .card-container .card ul li {
    list-style: none;
    padding: 10px;
}

#section5 .card-container .card .card-btn {
    text-decoration: none;
    color: red;
    padding: 10px;
    text-align: center;
    border: 1px solid red;
    border-radius: 10px;
    background-color: white;
}

#section5 .card-container .card .card-btn:hover {
    background-color: rgb(237, 237, 237);
}

hr {
    background-color: #fff;
    border-top: 1px dashed #8c8b8b;
}

#section5 h3 {
    text-align: center;
    font-weight: 700;
}

#section5 p {
    text-align: center;
    color: gray;
}

#section5 a {
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: rgb(0, 136, 255);
    padding: 10px 20px;
    border-radius: 10px;
}

/* section6 */

#section6 {
    padding: 100px 80px 100px 80px;
    width: 100%;
    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#section6 h1 {
    font-size: 4rem;
    font-weight: 800;
    margin-bottom: 50px;
}

#section6 h2 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 50px;
}

#section6 .small-container {
    margin-top: 150px;
    border-radius: 20px;
    background-color: rgb(0, 153, 255);
    color: white;
}

#section6 .small-container .purchase-btn {
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    color: black;
    text-decoration: none;
}

#section6 .landing {
    text-decoration: none;
    color: black;
    border: 1px solid gainsboro;
    padding: 10px 20px;
    border-radius: 10px;
    width: 200px;
}

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(167, 221, 173, 0.758),
            0 0 0 1em rgba(167, 221, 172, 0.196),

    }

    100% {
        box-shadow: 0 0 0 1em rgba(167, 221, 172, 0.196),
            0 0 0 3em rgba(167, 221, 172, 0.196);
    }
}

@media (max-width:1300px) {
    #section1 {
        width: 100%;
        height: auto;
        padding: 150px 50px 50px 50px;
    }

    #section1 h1 {
        font-size: 4rem;
        font-weight: 800;
        color: white;
    }

    #section2 {
        padding: 50px 20px 10px 20px;
    }

    #section3 {
        padding: 100px 50px 100px 50px;
        height: auto;
        display: block;
        justify-content: center;
    }

    #section3 h1 {
        font-size: 3rem;
        font-weight: 800;
    }

    #section4 {
        padding: 100px 50px 100px 50px;
        height: auto;
        display: block;
        justify-content: center;
    }

    #section4 h1 {
        font-size: 3rem;
        font-weight: 800;
    }

    #section5 {
        padding: 50px 20px 50px 20px;
    }

    #section5 h1 {
        font-size: 3rem;
        font-weight: 800;
    }

    #section6 {
        padding: 100px 50px 100px 50px;
        width: 100%;
        display: block;
        justify-content: center;
    }

    #section6 h1 {
        font-size: 3rem;
        font-weight: 800;
    }

}

@media (max-width:960px) {
    #section1 {
        width: 100%;
        height: 700px;
        padding: 100px 10px 10px 10px;
    }

    #section1::before {
        height: 700px;
        z-index: -1;
    }

    #section1 h1 {
        font-size: 2rem;
        font-weight: 800;
        color: white;
    }

    #section1 h6 {
        font-size: 0.8rem;
        font-weight: 300;
        letter-spacing: 0.8px;
    }

    #section1 .learn-more-btn {
        text-align: center;
        margin: 0;
    }

    #section2 {
        padding: 50px 20px 10px 20px;
    }

    #section2 h1 {
        font-size: 2rem;
        letter-spacing: 1px;
    }

    /* section3 */

    #section3 {
        padding: 100px 20px 50px 20px;
        height: auto;
        display: block;
        justify-content: center;
    }

    #section3 h1 {
        font-size: 2rem;
        font-weight: 800;
    }

    #section3 p {
        font-size: 0.85rem;
    }

    /* section4 */

    #section4 {
        padding: 50px 20px 50px 20px;
        height: auto;
        display: block;
        justify-content: center;
    }

    #section4 h1 {
        font-size: 2rem;
        font-weight: 800;
    }

    #section4 p {
        font-size: 0.85rem;
    }

    /* section5 */

    #section5 {
        padding: 50px 20px 50px 20px;
    }

    #section5 h1 {
        text-align: center;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 20px;
    }

    #section5 .card-container .card {
        padding: 20px 20px;
        background-color: white;
        border-radius: 20px;
        border: none;
    }

    #section5 .card-container .card h4 {
        font-weight: 700;
        margin-bottom: 25px;
    }

    #section5 .card-container .card p {
        font-size: 0.8rem;
        font-weight: 700;
        margin-bottom: 25px;
        color: gray;
    }

    #section5 .card-container .card .heart {
        width: 20px;
        margin-bottom: 15px;
    }

    #section5 .card-container .card .tick-li {
        width: 20px;
        margin-bottom: 5px;
        margin-right: 4px;
    }

    #section5 .card-container .card ul {
        padding: 0;
        margin: 0;
        margin-bottom: 25px;
    }

    #section5 .card-container .card ul li {
        list-style: none;
        padding: 5px;
    }

    #section5 .card-container .card .card-btn {
        text-decoration: none;
        color: red;
        padding: 10px;
        text-align: center;
        border: 1px solid red;
        border-radius: 10px;
        background-color: white;
    }

    #section5 .card-container .card .card-btn:hover {
        background-color: rgb(237, 237, 237);
    }

    hr {
        background-color: #fff;
        border-top: 1px dashed #8c8b8b;
    }

    /* section6 */

    #section6 {
        padding: 50px 20px 50px 20px;
        width: 100%;
        display: block;
        justify-content: center;
    }

    #section6 h1 {
        text-align: center;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 10px;
    }

    #section6 p {
        font-size: 0.85rem;
    }

    #section6 h2 {
        text-align: center;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 30px;
    }

    #section6 .small-container {
        margin-top: 20px;
        border-radius: 20px;
        background-color: rgb(0, 153, 255);
        color: white;
        padding-bottom: 50px;

    }

    #section6 .small-container img {
        width: 100%;
    }

    #section6 .small-container .purchase-btn {
        padding: 10px;
    }

    #section6 .landing {
        font-size: 0.8rem;
        text-align: center;
        padding: 5px 10px;
        border-radius: 10px;
        width: 150px;
        margin-bottom: 50px;
    }

    #section6 .landing-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
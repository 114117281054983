.footer {
    padding: 50px 200px 10px 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.footer .icon-container {
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.footer .icon-container img {
    width: 30px;
}

.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.footer-content-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-content h6 {
    font-weight: 800;
    margin-bottom: 20px;
}

.footer ul {
    padding: 0;
    margin: 0;
}

.footer ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.coming-soon {
    background-color: #00ab55;
    color: white;
    padding: 1px 3px;
    margin-left: 2px;
    border-radius: 3px;
}

.footer h6 a {
    color: black;
    text-decoration: none;
    font-weight: 700;
}

.footer .location-icon {
    width: 15px;
    margin-bottom: 5px;
}

.footer .tick-icon {
    width: 20px;
    margin-bottom: 5px;
}

.footer .about-icon,
.contact-icon,
.case-study-icon,
.career-icon,
.tc-icon,
.pp-icon {
    width: 17px;
    margin-bottom: 6px;
}

@media (max-width: 960px) {
    .footer {
        padding: 50px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer .icon-container {
        width: 150px;
    }

    .footer .icon-container img {
        width: 25px;
    }

    .footer-content {
        padding: 10px;
    }

    .footer-content h6 {
        font-weight: 800;
        margin-bottom: 10px;
    }

    .footer ul li {
        font-size: 0.7rem;
    }

    .footer .location-icon {
        width: 10px;
        margin-bottom: 4px;
    }

    .footer .tick-icon {
        width: 12px;
        margin-bottom: 3px;
    }

    .footer .about-icon,
    .contact-icon,
    .case-study-icon,
    .career-icon,
    .tc-icon,
    .pp-icon {
        width: 10px;
        margin-bottom: 5px;
    }
    .coming-soon {
        background-color: #00ab55;
        color: white;
        padding: 1px 3px;
        margin-left: 2px;
        border-radius: 3px;
    }
}
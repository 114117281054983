@import url('https://fonts.googleapis.com/css2?family=Monoton&family=Rammetto+One&display=swap');

.service {
    padding-top: 150px;
    margin: 0;
    height: 750px;
}

#service-content {
    padding-top: 150px;
    background-color: rgb(255, 255, 255);
}

#service-content h2 {
    font-weight: 800;
}

#service-form {
    box-shadow: 0px 0px 30px rgb(164, 164, 164);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.submit-btn {
    color: white;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    background-color: #0d6efd;
    border: none;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgb(116, 116, 116) !important;
}

@media (max-width: 900px) {
    #service-form {
        width: 100%;
        padding: 10px;
        background-color: rgb(255, 255, 255);
    }

    #service-content {
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .service {
        padding-top: 110px;
        height: auto;
    }

}